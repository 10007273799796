<template>
<aside ref="sidebar" class="sidenav navbar navbar-vertical navbar-expand-xs border-0 fixed-start bg-primary overflow-y-hidden" style="min-height: 100vh;z-index:999997 !important;">
    <div v-if="isTraining()" class="w-100 text-center py-1 alert-danger text-white fw-bolder font-monospace scale-in-ver-top" style="font-size: 12px !important; letter-spacing: 12px;">
        TRAINING
    </div>

    <div class="d-flex py-2 align-items-center justify-content-evenly">
        <img src="/img/logos/brands/o-g-white.png" style="height: 50px; width: 50px;" class="me-2">
        <div class="text-white font-weight-bolder m-0 p-0 text-center"><font-awesome-icon :icon="['fas', 'tags']" /> EXPOS</div>
        <div class="d-xl-none sidebarToggelButton justify-content-start rounded border bg-white text-primary py-1 px-3" @click="$store.commit('toggleSidebar')" style="font-size: 1rem;">
            <font-awesome-icon :icon="['fas', 'angle-double-left']" />
        </div>
    </div>
    <div class="bg-primary">
        <input-text-custom 
            class_="mx-1 my-2" 
            @keyup="handleSearch" 
            v-model="searchText" 
            placeholder_="Cari Menu..."></input-text-custom>
    </div>
    <!-- <div class="collapse navbar-collapse overflow-x-auto" style="height: 90%;"> -->
        <div class="collapse navbar-collapse overflow-x-auto" style="height: 85vh;">
        <ul class="navbar-nav" v-if="!isSearchMode">
            <div v-for="(p, index) in getMenu" :key="index">
                <div class="d-none" v-if="p.children.length == 0"></div>
                
                <li v-else-if="(p.children.length != 1) || !p.isSingleMenu">
                    <a class="nav-link text-white ps-0 ms-3 me-2" role="button" data-bs-toggle="collapse" :data-bs-target="'#' + p.name.toLowerCase().replaceAll(' ','')" aria-expanded="true">
                        <div class="text-white text-center d-flex align-items-center justify-content-center" style="width: 25px;height: 20px;">
                            <font-awesome-icon :icon="p.icon" />
                        </div> 
                        <span class="nav-link-text ms-1">{{ p.navLabel }}</span>
                        <div class="ms-auto">
                            <font-awesome-icon :icon="['fas', 'angle-down']" />
                        </div>
                    </a>

                    <ul :class="['navbar-nav collapse mb-2 rounded-bottom',(getSelectedMenu() == p.name.toLowerCase()) ? 'show':'']" :id="p.name.toLowerCase().replaceAll(' ','')" style="background-color:  #31446f !important;">
                
                        <li v-for="(c, index) in getSubMenu(p.children)" :key="index">
                            <a class="nav-link collapsed" role="button" data-bs-toggle="collapse" :data-bs-target="'#' + p.name.toLowerCase()+'-'+c.navLabel" aria-expanded="true">
                                <font-awesome-icon :icon="c.icon" class="me-2"/>{{formatNavTitle(c.navLabel) }}<br>
                                <div class="ms-auto">
                                    <font-awesome-icon :icon="['fas', 'angle-down']" />
                                </div>
                            </a>

                            <ul :class="['navbar-nav collapse mb-2 rounded-bottom',(this.$route.path.toLowerCase().replaceAll('/','').split('-').slice(0,2).join(' ')  == p.name.toLowerCase()+' '+c.navLabel.toLowerCase()) ? 'show':'']" :id="p.name.toLowerCase()+'-'+c.navLabel" style="background-color:  #2e3562 !important;">
                                <li v-for="(cm, index) in c.routes" :key="index" class="nav-item">
                                
                                    <!-- <router-link :to="cm.path" :class="['nav-link ', (this.$route.path.replaceAll('/','').toLowerCase() == cm.name.toLowerCase())? 'bg-dark-blue': '']">{{formatNavTitle(cm.name.toLowerCase().split('-').slice(2).join(' '))}} -->
                                        <router-link :to="cm.path" @click="moveToPage" :class="['nav-link ', (this.$route.path.replaceAll('/','').toLowerCase() == cm.name.toLowerCase())? 'bg-dark-blue': '']">{{ cm.navLabel }}
                                        <div class="ms-auto">
                                            <font-awesome-icon :icon="['fas', 'angle-right']" />
                                        </div>
                                    </router-link>
                                </li>
                            </ul>

                        </li>
                    </ul>

                </li>

                <li v-else>
                    <ul class="navbar-nav mb-2 rounded-bottom " :id="p.name" style="background-color: #31446f !important;">
                        <li class="nav-item">
                            <router-link :to="p.children[0].path" @click="moveToPage" :class="['nav-link ps-0 ms-3 me-2',(getSelectedChildMenu() ==  p.children[0].path.toLowerCase()) ? 'active':'']" >
                                <div class="text-white text-center d-flex align-items-center justify-content-center" style="width: 25px;height: 20px;">
                                    <font-awesome-icon :icon="p.icon" />
                                </div> 
                        <span class="nav-link-text ms-1">{{ formatNavTitle(p.children[0].navLabel) }}</span>
                        <div class="ms-auto">
                            <font-awesome-icon :icon="['fas', 'angle-right']" />
                        </div>
                            </router-link>
                        </li>
                    </ul>
                </li>
                
            </div>
        </ul>

        <ul class="navbar-nav" v-else>
            
            <div v-if="getMenu.length == 0">
                <div class="text-white text-center" style="font-size: 0.9rem !important;">Menu Tidak Ditemukan :(</div>
            </div>
            <div v-for="(p, index) in getMenu" :key="index">
                <li>
                    <ul class="navbar-nav rounded-bottom " :id="p.name" style="background-color: #31446f !important;">
                        <li class="nav-item">
                            <router-link :to="p.path" @click="moveToPage" :class="['nav-link ps-0 ms-3 me-2',(this.$route.path == p.path) ? 'active':'']" >
                                <span class="nav-link-text ms-1">{{p.navLabel }}</span>
                                <div class="ms-auto">
                                    <font-awesome-icon :icon="['fas', 'angle-right']" />
                                </div>
                            </router-link>
                        </li>
                    </ul>
                </li>
            </div>
        </ul>
    </div>

    </aside>
</template>

<script>
export default {
    data () {
        return {
            url: this.$route.path,
            sidebar: this.$router.options.routes.filter((menu)=>menu.isMenu),
            searchText: "",
            isSearchMode:false,
            debounceSidebar: null,
            isSidebarVisible: this.$store.state.sidebarVisible, // Bind to Vuex state or local state
            sidebarToggelButton:null,
        };
    },
    mounted() {
        this.sidebarToggelButton = document.getElementById('sidebarToggelButton');
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    },
    methods:{
        isTraining(){
            return window.location.origin.includes('training');
        },
        moveToPage(){
            if(this.$store.state.sidebarVisible && (window.innerWidth < 760)){
                this.$store.commit('toggleSidebar', false);
            }
        },
        handleClickOutside(event) {
            if( (window.innerWidth < 760)){
                const sidebar = this.$refs.sidebar;
                if (this.sidebarToggelButton != event.target && !sidebar.contains(event.target) && this.$store.state.sidebarVisible) {
                    this.closeSidebar();
                }
            }
        },
        closeSidebar() {
            this.$store.state.sidebarVisible = false;
        },
        // handleInput(event){
        //     console.log(event.target.value);
        //     const routeMenu = this.$router.options.routes.filter((menu)=>menu.isMenu);
        //     console.log(routeMenu.map(rm=> rm.children).flat());
        // },
        handleSearch(e) {
            if(this.searchText == ""){
                this.isSearchMode = false;
                return this.sidebar = this.$router.options.routes.filter((menu)=>menu.isMenu)
            }

            if(e.key == 'Enter') {
                const routeMenu = this.$router.options.routes.filter((menu)=>menu.isMenu); 
                const searchResult = routeMenu.map(rm=> rm.children.filter((menu)=>menu.navLabel.toLowerCase().includes(this.searchText.trim().toLowerCase())));
                this.isSearchMode = true;
                return this.sidebar = (searchResult.length != 0) ? searchResult.flat() : this.$router.options.routes.filter((menu)=>menu.isMenu);
            }
          
        },
        getSelectedMenu(){
            return this.$route.path.split('-')[0].replaceAll('/','').toLowerCase();   
        },
        getSelectedSubMenu(){
            return this.$route.path.toLowerCase().replaceAll('/','').split('-').slice(0,2).join('-')
        },
        getSelectedChildMenu(){
            return this.$route.path.replaceAll('/','').toLowerCase();
        },
        getSubMenu(menu){
            // Group routes by navLabel
            const groupedRoutesObject = menu.reduce((acc, route) => {
                if (!acc[route.name.split('-')[1]]) {
                    acc[route.name.split('-')[1]] = {
                        navLabel: route.name.split('-')[1],
                        icon: route.icon,
                        routes: []
                    };
                }
                acc[route.name.split('-')[1]].routes.push({
                    path: route.path,
                    name: route.name,
                    navLabel:route.navLabel,
                    roleAccess: route.roleAccess,
                    component: route.component
                });
                return acc;
            }, {});

            return groupedRoutesObject;
        },
        getNavTitle(submenuLabel,label){
            return label.toLowerCase().replaceAll(submenuLabel.toLowerCase(),'');
        },
        formatNavTitle(str){
            return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');              // Join the array back into a string
        }
    },
    computed:{
        getMenu(){
            return this.sidebar;
        },
    }
}
</script>

<style>

</style>