<template>
    <!-- Start Modal -->
    <div class="modal modal-xl fade" tabindex="-1" :id="'ModalPopup-'+title_" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header px-3 py-2 bg-primary">
                    <h1 class="modal-title fs-5 m-0 text-white">Daftar Data {{ title_ }}</h1> 
                    <button type="button" class="btn btn-danger m-0 px-2 py-1" data-bs-dismiss="modal"><font-awesome-icon :icon="['fas', 'times-circle']" style="font-size: 0.8rem !important;" /></button>
                </div>
                <div class="modal-body p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12">
                                <data-table :initialFields_="fields_" :api_="api_" v-if="!isFormMode" ref="dataTable" :CheckedAllItem_="checkedAllItem">
                                    <template #header="{item}" >
                                    {{ item }}
                                    </template>

                                    <template #action="{item}" >
                                        <a :class="['d-inline-block border border-1 rounded text-white lh-sm',(checkIfItemIsChecked(item)) ? 'bg-primary':'bg-white border-dark']" @click="checkedItem(item)" href="javascript:void(0)" style="width: 25px !important;height: 25px !important;">
                                            <font-awesome-icon :icon="['fas', 'check']" class="align-middle"/>
                                        </a>
                                    </template>

                                    <template #exhibitionStatus="{item}">
                                        <span :class="['badge m-au ',(item.exhibitionStatus ? 'bg-gradient-success':'bg-gradient-danger')]">{{ item.exhibitionStatus ? 'ACTIVE' : 'INACTIVE' }}</span>
                                    </template>
                                </data-table>
                            </div>
                            <div class="col-12 text-end px-4">
                                <button class="btn btn-primary" @click="addItems()">Tambah</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Modal -->

    <label :class="['mb-0 text-primary fw-bold',(hideView_) ? ' d-none':'']">{{ title_ }} <span v-if="required_" class="text-danger">*</span></label>
    <div v-if="!isMultiple" :class="'input-group ' + classGroup_ + ' ' + focused + ' ' + (error_ ? 'is-invalid': '') + ' ' + (hideView_ ? 'd-none':'')">
        <input  :type="type_" 
            :value="modelValue" 
            :class="['form-control ',(hideView_) ? 'd-none':''] + class_" 
            :placeholder="placeholder_" 
            :readonly="readonly_" 
            autocomplete="off" 
            @focus="focused = (!readonly_ ? 'focused is-focused': '')" 
            v-on:blur="modelValue ? '': (focused = '')"
            @input="$emit('update:modelValue', $event.target.value)"
            @keyup="$emit('keyup', $event)" disabled>
        <span class="input-group-text m-0 p-0 h-100">
            <button style="font-size: 0.9rem !important;padding-top: 0px;padding-bottom: 0px;padding-left: 10px;padding-right: 10px;" class="btn btn-primary m-0 shadow-none rounded-0 h-100" data-bs-toggle="modal" :data-bs-target="'#ModalPopup-'+title_" :disabled="disabled_"><font-awesome-icon :icon="['fas', 'book-open']" /></button>
            <button style="font-size: 1rem !important;padding-top: 0px;padding-bottom: 0px;padding-left: 10px;padding-right: 10px;" class="btn btn-danger m-0 shadow-none rounded-0 rounded-end h-100" :disabled="disabled_"><font-awesome-icon :icon="['fas', 'times-circle']" /></button>
        </span>
    </div>
    <div v-else :class="['d-flex', (hideView_ ? 'd-none':'')]">
        <div :class="'form-control d-flex flex-wrap gap-1 '+ class_" style="font-size: 12px !important; max-height: 100px; overflow-y:auto;">
            <span v-for="(item,index) in selectedItem" :key=index class="bg-primary rounded text-white px-2 py-1 fw-bolder user-select-none">{{ item[fields_[1]['key']] }}</span>            
        </div>
        <span class="d-flex m-0 p-0" style="height: 36px;">
            <button style="font-size: 1rem !important;padding-top: 0px;padding-bottom: 0px;padding-left: 10px;padding-right: 10px;" class="btn btn-primary m-0 shadow-none rounded-0 h-100" data-bs-toggle="modal" :data-bs-target="'#ModalPopup-'+title_" :disabled="disabled_"><font-awesome-icon :icon="['fas', 'book-open']" /></button>
            <button style="font-size: 1rem !important;padding-top: 0px;padding-bottom: 0px;padding-left: 10px;padding-right: 10px;" class="btn btn-danger m-0 shadow-none rounded-0 rounded-end h-100" :disabled="disabled_"><font-awesome-icon :icon="['fas', 'times-circle']" /></button>
        </span>
    </div>
    <small v-if="error_" :class="['text-danger fst-italic error-text lh-sm',(hideView_) ? 'd-none':'']">
            <span v-for="(error,index) in error_" :key="index" class="d-block">
                {{ error }}
            </span>
    </small>
</template>

<script>
import { Modal } from 'bootstrap';

export default {
    props: {
        classGroup_:        { type: String, default: 'input-group-outline' },   //input-group-dynamic
        class_:             { type: String, default: '' },
        title_:             { type: String },
        type_:              { type: String, default: 'text' },
        placeholder_:       { type: String },
        readonly_:          { type: Boolean, default: false },
        disabled_:          { type: Boolean, default: false },
        required_:          { type: Boolean, default: false },
        error_:             { type: Array },
        fields_:            { type: Object, default: () => {}, },
        api_:               { type: Object, default: () => {}, },
        modelValue:         { type: String },
        hideView_:          { type:Boolean , default:false },
        multipleSelect_:    { type:Boolean, default:false}, 
        onAddItem_:         { type:Function,default:()=>{}}
    },
    emits: ['update:modelValue', 'keyup'],
    mounted(){
        this.ModalPopupItem = new Modal("#ModalPopup-"+this.title_);
        this.isMultiple = this.multipleSelect_;
    },
    methods:{
        ShowModal(){
            this.selectedItem = [];
            this.$refs.dataTable.refresh();
            this.ModalPopupItem.show();
        },
        checkedItem(item){ 
            const found = this.selectedItem.some((i)=>i.Id == item.Id);
        
            if(this.isMultiple){
                if(found){
                    this.selectedItem = this.selectedItem.filter((i)=>i.Id !== item.Id);
                }else{
                    this.selectedItem.push(item);
                }
                this.$refs.dataTable.isCheckAllitem = (this.selectedItem.length === this.$refs.dataTable.dataFields.length);
            }else{
                if(!found){
                    this.selectedItem.pop();
                    this.selectedItem.push(item);
                }
            }
        },
        addItems(){
            this.onAddItem_();
            this.ModalPopupItem.hide();
        },
        checkIfItemIsChecked(item){
            return this.selectedItem.some((i) => i.Id == item.Id);
        },
        checkedAllItem(isChekedAll){
            if(this.isMultiple){
                this.selectedItem = [];
                this.selectedItem = isChekedAll ? this.$refs.dataTable.dataFields : [];
            }
        },
    },  
    beforeMount() {
        if (this.modelValue) { this.focused = 'focused is-focused' }
    },
    data: () => {
        return {
            focused: '',
            isFormMode:false,
            selectedItem:[],
            isMultiple:false,
        };
    }
}
</script>
<style scoped>
    .is-invalid{
        border: solid #dc3545 1px !important;
        border-radius: 0.375rem !important;
    }
</style>