import { createRouter, createWebHistory } from "vue-router";

const routes = Object.freeze([
    {
        path: '/login', 
        name: 'Login',
        isMenu: false,
        isSingleMenu: true,
        component: () => import("@/pages/auth/LoginPage.vue")
    },
    {
        path: '/hid-pl0001_ez/:name', 
        name: 'Hidden-Login',
        isMenu: false,
        isSingleMenu: false,
        component: () => import("@/pages/auth/HiddenLoginPage.vue")
    },
    {
        path: '/', 
        name: 'dashboard',
        navLabel: 'Beranda',
        redirect:'/dashboard',
        isMenu: true,
        isSingleMenu: true,
        icon: ['fas', 'th-large'],
        component: () => import("@/pages/non-sales/MainProject.vue"),
        children: [
            {
                path: '/dashboard',
                name: 'dashboard',
                navLabel: 'Beranda',
                icon: ['fas', 'th-large'],
                menu:'master',
                roleAccess:['ADMIN','CASHIRE','KORLAP','SALES_COUNTER','FM_DCM','PIC_MARKETING','SPV_FIELD','SPV_BAM','MANAGER_MATTRESS','DIR_MARKETING', 'VICE_CEO','GM_MATTRESS','GM','AM','PM'],
                component: () => import('@/pages/non-sales/dashboard/LandingPage.vue')
            },
        ]
    },
    {
        path: '/pos', 
        name: 'pos',
        navLabel: 'P.O.S',
        isMenu: true, 
        isSingleMenu: false,
        icon: ['fas', 'cash-register'],
        component: () => import("@/pages/non-sales/MainProject.vue"),
        children: [
            {
                path: '/pos-transaksi-sales-order',
                name: 'pos-transaksi-sales-order',
                navLabel: 'Sales Order',
                icon: ['fas', 'exchange-alt'],
                roleAccess:['ADMIN','CASHIRE'],
                component: () => import('@/pages/non-sales/pos/transaction/sales order/LandingPage.vue')
            },
            // {
            //     path: '/pos-transaksi-sales-return',
            //     name: 'pos-transaksi-sales-return',
            //     navLabel: 'Sales Return',
            //     icon: ['fas', 'exchange-alt'],
            //     roleAccess:['ADMIN','CASHIRE'],
            //     component: () => import('@/pages/non-sales/pos/transaction/sales return/LandingPage.vue')
            // },
            {
                path: '/pos-transaksi-sales-order-goes',
                name: 'pos-transaksi-sales-order-goes',
                navLabel: 'Sales Order To GOES',
                icon: ['fas', 'exchange-alt'],
                roleAccess:['ADMIN','SALES_COUNTER'],
                component: () => import('@/pages/non-sales/pos/transaction/sales-order-goes/LandingPage.vue')
            },
            {
                path: '/pos-transaksi-sales-order-delivery',
                name: 'pos-transaksi-sales-order-delivery',
                navLabel: 'Sales Order Delivery',
                icon: ['fas', 'exchange-alt'],
                roleAccess:['ADMIN','SALES_COUNTER'],
                component: () => import('@/pages/non-sales/pos/transaction/sales-order-delivery/LandingPage.vue')
            },
            {
                path: '/pos-transaksi-keluaran',
                name: 'pos-transaksi-keluaran',
                navLabel: 'Keluaran Barang',
                icon: ['fas', 'exchange-alt'],
                roleAccess:['ADMIN','CASHIRE'],
                component: () => import('@/pages/non-sales/pos/transaction/keluaran-barang/LandingPage.vue')
            },
            {
                path: '/pos-transaksi-keluaran-hadiah-survey',
                name: 'pos-transaksi-keluaran-hadiah-survey',
                navLabel: 'Hadiah Survey',
                icon: ['fas', 'exchange-alt'],
                roleAccess:['ADMIN','CASHIRE'],
                component: () => import('@/pages/non-sales/pos/transaction/keluaran-hadiah-survey/LandingPage.vue')
            },
            {
                path: '/pos-transaksi-generate-stock',
                name: 'pos-transaksi-generate-stock',
                navLabel: 'Generate Koreksi Stock',
                icon: ['fas', 'exchange-alt'],
                roleAccess:['ADMIN','SALES_COUNTER'],
                component: () => import('@/pages/non-sales/pos/transaction/generate-ks/LandingPage.vue')
            },
            {
                path: '/pos-transaksi-stock-adjustment',
                name: 'pos-transaksi-stock-adjustment',
                navLabel: 'Koreksi Stock',
                icon: ['fas', 'exchange-alt'],
                roleAccess:['ADMIN', 'SALES_COUNTER'],
                component: () => import('@/pages/non-sales/pos/transaction/stock-adjustment/LandingPage.vue')
            },
            {
                path: '/pos-transaksi-transfer-warehouse',
                name: 'pos-transaksi-transfer-warehouse',
                navLabel: 'Transfer Warehouse',
                icon: ['fas', 'exchange-alt'],
                roleAccess:['ADMIN','SALES_COUNTER'],
                component: () => import('@/pages/non-sales/pos/transaction/transfer-warehouse/LandingPage.vue')
            },
            {
                path: '/pos-transaksi-petty-cash',
                name: 'pos-transaksi-petty-cash',
                navLabel: 'Kas Kecil',
                icon: ['fas', 'exchange-alt'],
                roleAccess:['CASHIRE'],
                component: () => import('@/pages/non-sales/pos/transaction/petty-cash/LandingPage.vue')
            },
            {
                path: '/pos-laporan-recap-order',
                name: 'pos-laporan-recap-order',
                navLabel: 'Rekap Sales Order',
                icon: ['fas', 'file-invoice'],
                roleAccess:['ADMIN','FM_DCM','KORLAP','SALES_COUNTER', 'CASHIRE','DIR_MARKETING', 'VICE_CEO'],
                component: () => import('@/pages/non-sales/pos/report/recap-order/LandingPage.vue')
            },
            {
                path: '/pos-laporan-product-sales',
                name: 'pos-laporan-product-sales',
                navLabel: 'Penjualan Produk',
                icon: ['fas', 'file-invoice'],
                roleAccess:['ADMIN','CASHIRE','FM_DCM','KORLAP','PIC_MARKETING','SPV_FIELD','SPV_BAM','MANAGER_MATTRESS','DIR_MARKETING', 'VICE_CEO','GM_MATTRESS','GM','AM','PM'],
                component: () => import('@/pages/non-sales/pos/report/product-sales/LandingPage.vue')
            },
            {
                path: '/pos-laporan-exhibition',
                name: 'pos-laporan-exhibition',
                navLabel: 'Exhibition',
                icon: ['fas', 'file-invoice'],
                roleAccess:['ADMIN','FM_DCM','KORLAP','PIC_MARKETING','SPV_FIELD','SPV_BAM','MANAGER_MATTRESS','DIR_MARKETING', 'VICE_CEO','GM_MATTRESS','GM','AM','PM'],
                component: () => import('@/pages/non-sales/pos/report/exhibition/LandingPage.vue')
            },
            {
                path: '/pos-laporan-pengiriman',
                name: 'pos-laporan-pengiriman',
                navLabel: 'Delivery',
                icon: ['fas', 'file-invoice'],
                roleAccess:['ADMIN','CASHIRE','FM_DCM','KORLAP','SALES_COUNTER'],
                component: () => import('@/pages/non-sales/pos/report/shipping-schedule/LandingPage.vue')
            },
            {
                path: '/pos-laporan-recap-stock',
                name: 'pos-laporan-recap-stock',
                navLabel: 'Laporan Persediaan',
                icon: ['fas', 'file-invoice'],
                roleAccess:['ADMIN','CASHIRE','FM_DCM','KORLAP','SALES_COUNTER'],
                component: () => import('@/pages/non-sales/pos/report/recap-stock/LandingPage.vue')
            },
            {
                path: '/pos-laporan-recap-petty-cash',
                name: 'pos-laporan-recap-petty-cash',
                navLabel: 'Rekap Kas Kecil',
                icon: ['fas', 'file-invoice'],
                roleAccess:['ADMIN','CASHIRE'],
                component: () => import('@/pages/non-sales/pos/report/recap-petty-cash/LandingPage.vue')
            },
        ]
    },
    {
        path: '/setup', 
        name: 'setup',
        navLabel: 'Setup',
        isMenu: true,
        isSingleMenu: false,
        icon: ['fas', 'cogs'],
        component: () => import("@/pages/non-sales/MainProject.vue"),
        children: [
            {
                path: '/setup-master-general',
                name: 'setup-master-general',
                navLabel: 'General',
                icon: ['fas', 'archive'],
                roleAccess:['ADMIN'],
                component: () => import('@/pages/non-sales/master/general/LandingPage.vue')
            },
            {
                path: '/setup-master-brand',
                name: 'setup-master-brand',
                navLabel: 'Brand',
                icon: ['fas', 'archive'],
                roleAccess:['ADMIN'],
                component: () => import('@/pages/non-sales/master/brand/LandingPage.vue')
            },
            {
                path: '/setup-master-area',
                name: 'setup-master-area',
                navLabel: 'Area',
                icon: ['fas', 'archive'],
                roleAccess:['ADMIN'],
                component: () => import('@/pages/non-sales/master/area/LandingPage.vue')
            },
            {
                path: '/setup-master-areaAssign',
                name: 'setup-master-areaAssign',
                navLabel: 'Area Assign',
                icon: ['fas', 'archive'],
                roleAccess:['ADMIN', 'CASHIRE'],
                component: () => import('@/pages/non-sales/master/areaAssign/LandingPage.vue')
            },
            {
                path: '/setup-master-campaign',
                name: 'setup-master-campaign',
                navLabel: 'Campaign',
                icon: ['fas', 'archive'],
                roleAccess:['ADMIN'],
                component: () => import('@/pages/non-sales/master/campaign/LandingPage.vue')
            },
            {
                path: '/setup-master-exhibition',
                name: 'setup-master-exhibition',
                navLabel: 'Exhibition',
                icon: ['fas', 'archive'],
                roleAccess:['ADMIN'],
                component: () => import('@/pages/non-sales/master/exhibition/LandingPage.vue')
            },
            {
                path: '/setup-master-company',
                name: 'setup-master-company',
                navLabel: 'Bisnis Unit',
                icon: ['fas', 'archive'],
                roleAccess:['ADMIN'],
                component: () => import('@/pages/non-sales/master/company/LandingPage.vue')
            },
            {
                path: '/setup-master-user',
                name: 'setup-master-user',
                navLabel: 'User',
                icon: ['fas', 'archive'],
                roleAccess:['ADMIN'],
                component: () => import('@/pages/non-sales/master/user/LandingPage.vue')
            },
            {
                path: '/setup-master-customer',
                name: 'setup-master-customer',
                navLabel: 'Customer',
                icon: ['fas', 'archive'],
                roleAccess:['ADMIN'],
                component: () => import('@/pages/non-sales/master/customer/LandingPage.vue')
            },
            {
                path: '/setup-master-promotion',
                name: 'setup-master-promotion',
                navLabel: 'Promosi',
                icon: ['fas', 'archive'],
                roleAccess:['ADMIN','CASHIRE'],
                component: () => import('@/pages/non-sales/master/promotion/LandingPage.vue')
            },
            {
                path: '/setup-master-item',
                name: 'setup-master-item',
                navLabel: 'Item',
                icon: ['fas', 'archive'],
                roleAccess:['ADMIN'],
                component: () => import('@/pages/non-sales/master/item/LandingPage.vue')
            },
            {
                path: '/setup-master-item-assign',
                name: 'setup-master-item-assign',
                navLabel: 'Item Assign',
                icon: ['fas', 'archive'],
                roleAccess:['ADMIN', 'CASHIRE'],
                component: () => import('@/pages/non-sales/master/itemAssign/LandingPage.vue')
            },
            {
                path: '/setup-master-warehouse',
                name: 'setup-master-warehouse',
                navLabel: 'Warehouse',
                icon: ['fas', 'archive'],
                roleAccess:['ADMIN'],
                component: () => import('@/pages/non-sales/master/warehouse/LandingPage.vue')
            },
            // {
            //     path: '/setup-laporan-price',
            //     name: 'setup-laporan-price',
            //     navLabel: 'Price',
            //     icon: ['fas', 'file-invoice'],
            //     roleAccess:['ADMIN','CASHIRE'],
            //     component: () => import('@/pages/non-sales/master/company/LandingPage.vue')
            // },
            {
                path: '/setup-laporan-master-item',
                name: 'setup-laporan-master-item',
                navLabel: 'Master Item',
                icon: ['fas', 'file-invoice'],
                roleAccess:['ADMIN'],
                component: () => import('@/pages/non-sales/master/report/recap-master-item/LandingPage.vue')
            },
            {
                path: '/setup-laporan-item-assign',
                name: 'setup-laporan-item-assign',
                navLabel: 'Item Assign',
                icon: ['fas', 'file-invoice'],
                roleAccess:['ADMIN'],
                component: () => import('@/pages/non-sales/master/report/recap-item-assign/LandingPage.vue')
            },   
        ]
    },
    {
        path: '/404',
        name: '404',
        isMenu: false,
        isSingleMenu: true,
        component: () => import('@/pages/error/404Page.vue')
    },
    {
        path: "/:catchall(.*)*",
        component: () => import('@/pages/error/404Page.vue'),
        isSingleMenu: true,
        isMenu: false
    }
])

function getMyRoutes(){
    const data = window.sessionStorage.getItem('expos');
    if(!data) return routes
    const userTypes = JSON.parse(data)['UserType'];
    const allowedRoutes = routes.reduce((item, obj) => {
        if(obj.isMenu && userTypes != 'ADMIN'){
            var temp = { ...obj };
            temp.children = temp.children.filter(child=> child.roleAccess.includes(userTypes))
            return [ ...item, temp ];
        }
        if(obj.isMenu && obj.children.length === 0){
            console.log('s');
        }else{
            return [ ...item, obj ]
        }
    }, [])
    return allowedRoutes
}

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: getMyRoutes(),
});

router.beforeEach((to, from, next) => {
    const data = window.sessionStorage.getItem('expos');
    const token = JSON.parse(data);
    if ((!data || !token['Token']) && to.name !== 'Login' && to.name !== '404' &&  to.name !== 'Hidden-Login'){
        next('/login');
    }else{
        // if(to.name === 'Login') next('/');
        const titleName = (to.name?.split("-").length > 1) ? to.name?.split("-").slice(2).join(' ') : to.name;
        document.title = `${titleName.toUpperCase()} - [EXPOS]`;
        next();
    }
})

export {
    router,
    getMyRoutes,
    routes
}
